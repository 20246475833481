<template>
    <div id="Profile">
        <div class="r-box"> 
            <div class="r-header">
                <h1>Setting Web</h1>
            </div>
            <div class="r-body">
                <div class="y-inputField">
                    <div class="y-labelText">
                        Status
                    </div>
                    <div class="y-inputPlace y-hasIcon-right">
                        <div class="y-select-hasIcon" style="width: 100%">
                            <select class="y-select" v-model="data.status">
                                <option class="y-option" value="1">Online</option>
                                <option class="y-option" value="0">Offline</option>
                            </select>
                            <div class="y-select-icon">
                                <img :src="require('@/assets/icons/others/arrowDown.svg')" alt="">
                            </div>
                        </div>
                    </div>
                    <div
                        class="y-errorText y-red-text"
                        :style="error.status ? 'opacity: 1 !important;': ''"
                    >
                        {{errorMsg.status}}
                    </div>
                </div>

                <InputCustom
                    v-model="data.name"
                    :error="error.name"
                    :errorMsg="errorMsg.name"
                    @onFocus="resetError('name')"
                    placeholder="Nama"
                    label="Nama"
                >
                
                </InputCustom>
                
                <InputCustom
                    v-model="data.email"
                    :error="error.email"
                    :errorMsg="errorMsg.email"
                    @onFocus="resetError('email')"
                    placeholder="Email"
                    label="Email"
                >
                
                </InputCustom>
                
                <InputCustom
                    v-model="data.whatsapp"
                    :error="error.whatsapp"
                    :errorMsg="errorMsg.whatsapp"
                    @onFocus="resetError('whatsapp')"
                    placeholder="Whatsapp"
                    label="Whatsapp"
                >
                
                </InputCustom>
                
                <InputCustom
                    v-model="data.sms"
                    :error="error.sms"
                    :errorMsg="errorMsg.sms"
                    @onFocus="resetError('sms')"
                    placeholder="SMS"
                    label="SMS"
                >
                
                </InputCustom>

                <InputCustom
                    v-model="data.pin_bb"
                    :error="error.pin_bb"
                    :errorMsg="errorMsg.pin_bb"
                    @onFocus="resetError('pin_bb')"
                    placeholder="Pin BB"
                    label="Pin BB"
                >
                
                </InputCustom>
                
                <InputCustom
                    v-model="data.ym1"
                    :error="error.ym1"
                    :errorMsg="errorMsg.ym1"
                    @onFocus="resetError('ym1')"
                    placeholder="YM 1"
                    label="YM 1"
                >
                
                </InputCustom>
                
                <InputCustom
                    v-model="data.ym2"
                    :error="error.ym2"
                    :errorMsg="errorMsg.ym2"
                    @onFocus="resetError('ym2')"
                    placeholder="YM 2"
                    label="YM 2"
                >
                
                </InputCustom>
                

                <InputCustom
                    v-model="data.line"
                    :error="error.line"
                    :errorMsg="errorMsg.line"
                    @onFocus="resetError('line')"
                    placeholder="Line"
                    label="Line"
                >
                
                </InputCustom>
                
                <InputCustom
                    v-model="data.wechat"
                    :error="error.wechat"
                    :errorMsg="errorMsg.wechat"
                    @onFocus="resetError('wechat')"
                    placeholder="Wechat"
                    label="Wechat"
                >
                
                </InputCustom>

                <TextareaCustom
                    v-model="data.pengumuman" 
                    :error="error.pengumuman"
                    :errorMsg="errorMsg.pengumuman"
                    @onFocus="resetError('pengumuman')"
                    placeholder="Masukkan Pengumuman"
                    label="Pengumuman"
                >

                </TextareaCustom>
                
                <InputCustom
                    v-model="data.komisi"
                    :error="error.komisi"
                    :errorMsg="errorMsg.komisi"
                    @onFocus="resetError('komisi')"
                    placeholder="Komisi"
                    label="Komisi"
                >
                
                </InputCustom>

                <TextareaCustom
                    v-model="data.meta_keyword" 
                    :error="error.meta_keyword"
                    :errorMsg="errorMsg.meta_keyword"
                    @onFocus="resetError('meta_keyword')"
                    placeholder="Meta Keywords"
                    label="Meta Keywords"
                >

                </TextareaCustom>

                <TextareaCustom
                    v-model="data.meta_description" 
                    :error="error.meta_description"
                    :errorMsg="errorMsg.meta_description"
                    @onFocus="resetError('meta_description')"
                    placeholder="Meta Description"
                    label="Meta Description"
                >

                </TextareaCustom>

                <TextareaCustom
                    v-model="data.meta_title" 
                    :error="error.meta_title"
                    :errorMsg="errorMsg.meta_title"
                    @onFocus="resetError('meta_title')"
                    placeholder="Meta Title"
                    label="Meta Title"
                >

                </TextareaCustom>
            </div>
            <div class="r-footer">
                <div class="r-center-flex">
                    <button class="y-primary-btn y-hasspace-btn y-sm-size" style="margin-left: auto" @click.prevent="sendData()">
                        <span v-if="!loading">Save</span>
                        <img :src="require('@/assets/loadingDot.svg')" alt style="width: 50px;" v-else />   
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex"
import common from '@/mixins/function/common'
import tableMixins from '@/mixins/tableMixins'
import validation from '@/mixins/function/validation'
import TextareaCustom from '@/components/Helper/Textarea'
export default {
    mixins: [
        common, 
        tableMixins,
        validation,
    ],
    components: {
        TextareaCustom
    },
    async mounted() {
        await this.$store.restored
        this.$store.commit("SET_ACTIVETAB", "");
        var breadcrumb = [
            {
                name: "Setting Web",
                link: {},
                current: true
            }
        ]
        this.$store.commit("SET_BREADCRUMB", breadcrumb);
        await this.getData()
    },
    computed: {
        ...mapGetters ({
            data: 'web/getData',
        }),
    },
    data: () => ({
        loading: false,
        error: {
            name: null,
            email: null,
            whatsapp: null,
            sms: null,
            pin_bb: null,
            ym1: null,
            ym2: null,
            line: null,
            wechat: null,
            pengumuman: null,
            komisi: null,
            meta_keyword: null,
            meta_description: null,
            meta_title: null,
            status: null,
        },
        errorMsg: {
            name: "Tidak Boleh Kosong",
            email: "Tidak Boleh Kosong",
            whatsapp: "Tidak Boleh Kosong",
            sms: "Tidak Boleh Kosong",
            pin_bb: "Tidak Boleh Kosong",
            ym1: "Tidak Boleh Kosong",
            ym2: "Tidak Boleh Kosong",
            line: "Tidak Boleh Kosong",
            wechat: "Tidak Boleh Kosong",
            pengumuman: "Tidak Boleh Kosong",
            komisi: "Tidak Boleh Kosong",
            meta_keyword: "Tidak Boleh Kosong",
            meta_description: "Tidak Boleh Kosong",
            meta_title: "Tidak Boleh Kosong",
            status: "Tidak Boleh Kosong",
        },
    }),
    methods: {
        ...mapActions ({
            getDataWeb: 'web/fetchData',
            store: 'web/store',
        }),
        setShowPassword(value){
            if(this.showpassword == value){
                this.showpassword = null
            }else{
                this.showpassword = value
            }
        },
        validation() {
            var error = 0
            if(this.data.komisi !== null && this.data.komisi !== ""){
                if(!(/^\d+$/.test(this.data.komisi))){
                    this.error.komisi = true
                    this.errorMsg.komisi = "Hanya boleh angka"
                    error += 1;
                } else if (this.data.komisi < 0 || this.data.komisi > 100){
                    this.error.komisi = true
                    this.errorMsg.komisi = "Range komisi 1 - 100"
                    error += 1;
                } else{
                    this.error.komisi = false
                }
            }
            return error
        },
        async sendData() {
            if (this.validation() === 0) {
                this.loading = true
                await this.store(this.data)
                this.loading = false
                const response = this.getResponse('web')
                if (response.status == 1) {
                    this.showSnackbar ({
                        type: 'success',
                        text: response.msg
                    })
                } else {
                    this.showSnackbar ({
                        type:'error',
                        text: response.msg
                    })
                }
            } else {
                this.showSnackbar ({
                    type: 'error',
                    text: 'Mohon Periksa Kembali'
                })
            }
        },
        async getData() {
            await this.getDataWeb()
            var response = this.getResponse('web')
            if (response.status === 0) {
                this.showSnackbar ({
                    type: 'error',
                    text: response.msg
                })
            }
        },
    }
}
</script>
<style lang="scss">
    #Profile{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        .r-center-flex{
            .y-inputField{
                flex: 1;
                padding: 0px 16px 0px 0px;
            }
        }
        .r-box{
            width: 100%;
            padding: 0px 0px 16px 16px;
            .y-inputField{
                padding: 0px 16px 0px 0px;
            }
        }
    }
</style>